/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Main css of index file as global style
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

div {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
